var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "register-owner" },
    [
      _c(
        "CForm",
        [
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { md: "8" } }, [
                _c("h1", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "pages.auth.registerOwner.title",
                      expression: "'pages.auth.registerOwner.title'"
                    }
                  ]
                }),
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("pages.auth.registerOwner.infoText")
                    )
                  }
                }),
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("pages.auth.registerOwner.linkForGuest", {
                        link: _vm.customerRegisterLink
                      })
                    )
                  }
                })
              ]),
              _c("CCol", { attrs: { md: "4" } })
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { md: "8" } }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm.message
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger",
                          attrs: { role: "alert" }
                        },
                        [_vm._v(" " + _vm._s(_vm.message) + " ")]
                      )
                    : _vm._e()
                ])
              ]),
              _c("CCol", { attrs: { md: "4" } })
            ],
            1
          ),
          _c(
            "CRow",
            [
              _vm.maintenanceMode
                ? _c(
                    "CCol",
                    { attrs: { md: "8" } },
                    [
                      _c(
                        "CCard",
                        [
                          _c("CCardBody", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "pages.auth.registerOwner.maintenanceMode",
                                expression:
                                  "'pages.auth.registerOwner.maintenanceMode'"
                              }
                            ]
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.maintenanceMode
                ? _c(
                    "CCol",
                    { attrs: { md: "8" } },
                    [
                      _c(
                        "CCard",
                        [
                          _c(
                            "CCardBody",
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.user.displayname,
                                  value: _vm.$v.user.displayname.$model,
                                  translationKey: "owner.displayName",
                                  autocomplete: "username",
                                  "data-cy": "displayName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.displayname,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [
                                          _c("CIcon", {
                                            attrs: { name: "cil-user" }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3945887885
                                )
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.user.email,
                                  value: _vm.$v.user.email.$model,
                                  translationKey: "owner.email",
                                  autocomplete: "email",
                                  "data-cy": "email"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.email,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [_vm._v("@")]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3965286753
                                )
                              }),
                              !_vm.$v.user.email.emailIsUnique
                                ? _c(
                                    "div",
                                    [
                                      _c("router-link", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.auth.Login.forgotPassword",
                                            expression:
                                              "'pages.auth.Login.forgotPassword'"
                                          }
                                        ],
                                        attrs: {
                                          to: { name: "ForgotPassword" },
                                          "data-cy": "forgot-password"
                                        }
                                      }),
                                      _vm._v(" | "),
                                      _c("router-link", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.auth.Login.resendConfirmation",
                                            expression:
                                              "'pages.auth.Login.resendConfirmation'"
                                          }
                                        ],
                                        attrs: {
                                          to: { name: "ResendConfirmation" },
                                          "data-cy": "resend-confirmation"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.user.password,
                                  value: _vm.$v.user.password.$model,
                                  translationKey: "owner.password",
                                  autocomplete: "new-password",
                                  "data-cy": "password",
                                  type: "password"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.password,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [
                                          _c("CIcon", {
                                            attrs: { name: "cil-lock-locked" }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3300492400
                                )
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey: "owner.passwordConfirmation",
                                  field: _vm.$v.user.passwordConfirmation,
                                  value:
                                    _vm.$v.user.passwordConfirmation.$model,
                                  autocomplete: "new-password",
                                  "data-cy": "passwordConfirmation",
                                  type: "password"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.passwordConfirmation,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [
                                          _c("CIcon", {
                                            attrs: { name: "cil-lock-locked" }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3300492400
                                )
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey: "owner.promotionCode",
                                  field: _vm.$v.user.promotionCode,
                                  value: _vm.$v.user.promotionCode.$model,
                                  "data-cy": "promotionCode"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.promotionCode,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        [
                          _c(
                            "CCardBody",
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey: "owner.firstname",
                                  field: _vm.$v.user.firstname,
                                  value: _vm.$v.user.firstname.$model,
                                  autocomplete: "given-name",
                                  "data-cy": "firstName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.firstname,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey: "owner.lastname",
                                  field: _vm.$v.user.lastname,
                                  value: _vm.$v.user.lastname.$model,
                                  autocomplete: "family-name",
                                  "data-cy": "lastName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.lastname,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              }),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("ValidatedInput", {
                                        attrs: {
                                          translationKey: "owner.companyName",
                                          "data-cy": "companyName",
                                          field: _vm.$v.user.companyName,
                                          value: _vm.$v.user.companyName.$model
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user.companyName,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("ValidatedSelect", {
                                        attrs: {
                                          field: _vm.$v.user.companyLegalType,
                                          options: _vm.companyLegalTypeOptions,
                                          value:
                                            _vm.$v.user.companyLegalType.$model,
                                          translationKey:
                                            "playroom.companyLegalType"
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user.companyLegalType,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isCompanyLegalNameDisabled
                                    ? _c(
                                        "CCol",
                                        { attrs: { md: "12" } },
                                        [
                                          _c("ValidatedInput", {
                                            attrs: {
                                              field:
                                                _vm.$v.user.companyLegalName,
                                              value:
                                                _vm.$v.user.companyLegalName
                                                  .$model,
                                              translationKey:
                                                "playroom.companyLegalName"
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.$set(
                                                  _vm.$v.user.companyLegalName,
                                                  "$model",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("AddressForm", {
                                attrs: {
                                  field: _vm.$v.user,
                                  value: _vm.$v.user.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        [
                          _c(
                            "CCardBody",
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "pages.auth.registerCustomer.emailSetting"
                                    )
                                  )
                                }
                              }),
                              _c("hr", { staticStyle: { "margin-top": "0" } }),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "allowEmailForPersonalOffersAt"
                                          ),
                                          name:
                                            "user.allowEmailForPersonalOffersAt",
                                          checked:
                                            _vm.$v.user
                                              .allowEmailForPersonalOffersAt
                                              .$model,
                                          translationKey:
                                            "owner.allowEmailForPersonalOffersAt",
                                          field:
                                            _vm.$v.user
                                              .allowEmailForPersonalOffersAt
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user
                                                .allowEmailForPersonalOffersAt,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "allowEmailForFeedbackAt"
                                          ),
                                          name: "user.allowEmailForFeedbackAt",
                                          checked:
                                            _vm.$v.user.allowEmailForFeedbackAt
                                              .$model,
                                          translationKey:
                                            "owner.allowEmailForFeedbackAt",
                                          field:
                                            _vm.$v.user.allowEmailForFeedbackAt
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user
                                                .allowEmailForFeedbackAt,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        [
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "privacyCheck"
                                          ),
                                          name: "user.privacyCheck",
                                          checked:
                                            _vm.$v.user.privacyCheck.$model,
                                          translationKey: "owner.privacyCheck",
                                          field: _vm.$v.user.privacyCheck
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user.privacyCheck,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "businessTerms"
                                          ),
                                          name: "user.businessTerms",
                                          checked:
                                            _vm.$v.user.businessTerms.$model,
                                          translationKey:
                                            "owner.acceptBusinessTerms",
                                          field: _vm.$v.user.businessTerms
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user.businessTerms,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "acceptBooking"
                                          ),
                                          name: "user.acceptBooking",
                                          checked:
                                            _vm.$v.user.acceptBooking.$model,
                                          translationKey: "owner.acceptBooking",
                                          field: _vm.$v.user.acceptBooking
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user.acceptBooking,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { md: "12" }
                            },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "px-4",
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      _vm.loading || !_vm.checkPrivacyChecked,
                                    "data-cy": "submit-registration"
                                  },
                                  on: { click: _vm.handleSubmit }
                                },
                                [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading,
                                        expression: "loading"
                                      }
                                    ],
                                    staticClass:
                                      "spinner-border spinner-border-sm"
                                  }),
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "pages.auth.registerOwner.button",
                                        expression:
                                          "'pages.auth.registerOwner.button'"
                                      }
                                    ]
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c(
                    "CCard",
                    [
                      _c("CCardHeader", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "pages.auth.registerOwner.info.password.title"
                            )
                          )
                        }
                      }),
                      _c("CCardBody", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "pages.auth.registerOwner.info.password.info"
                            )
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "CCard",
                    [
                      _c("CCardHeader", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("pages.auth.registerOwner.info.box1.title")
                          )
                        }
                      }),
                      _c("CCardBody", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("pages.auth.registerOwner.info.box1.text")
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showNotification
        ? _c("Notification", {
            attrs: {
              text: _vm.$t("pages.auth.registerOwner.nofificationText")
            },
            on: {
              onClose: function() {
                return _vm.handleCloseNotification()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }